import { WFComponent, WFFormComponent } from "@xatom/core";
import { publicQL } from "../../graphql";
import { PublicUploadAvatarDocument, UserMeDocument } from "../../graphql/graphql";
import { S3_BASE_URL } from "../../config";
import { logoutPublicAuth } from "../../auth/public";
const defaultUsrAvatar = "https://assets-global.website-files.com/plugins/Basic/assets/placeholder.60f9b1840c.svg";
let loaderProgress = 0;

export const userSidebar = () => {
    console.log("Function executed");
    try {
        const userDataReq = publicQL.query(UserMeDocument);
        const avatarChangeReq = publicQL.mutation(PublicUploadAvatarDocument);
        const logoutBtn = new WFComponent(`[xa-type="sidebar-signout-btn"]`);
        logoutBtn.on("click", () => {
            logoutPublicAuth();
        });
        const sidebar = new WFComponent(`[xa-type="sidebar-component"]`);
        const avatar = sidebar.getChildAsComponent(`[xa-type="avatar"]`);
        const avatarGradient = avatar.getChildAsComponent(`[xa-type="border"]`);

        userDataReq.onData((data) => {
            if (!data.userMe.enabled) {
                logoutPublicAuth();
            } else {
                sidebar.updateTextViaAttrVar({
                    name: `${data.userMe.firstName} ${data.userMe.lastName}`,
                    email: data.userMe.email,
                    initials: getInitials(`${data.userMe.firstName} ${data.userMe.lastName}`)
                });
                const userTier = data.userMe.tier.name
                switch (userTier) {
                    case 'Bronze':
                        avatarGradient.addCssClass("is-bronze");
                        break;

                    case 'Silver':
                        avatarGradient.addCssClass("is-silver");
                        break;

                    case 'Gold':
                        avatarGradient.addCssClass("is-gold");
                        break;

                    default:
                        break;
                }
            }
        });
        
        userDataReq.fetch();
    } catch (err) {
        console.log("We think this page doesn't have a sidebar. Check this error - ", err.message);
    }


    function getInitials(name) {
        const words = name.split(' ');
        const initials = words.map(word => word.charAt(0).toUpperCase()).join('');
        return initials;
    }


    function getRandomValueFromArray(array) {
        const randomIndex = Math.floor(Math.random() * array.length);
        return array[randomIndex];
    }

    function loaderAnimation(loader) {
        loader.getElement().style.background = `conic-gradient(#00B8B4 ${loaderProgress * 3.6}deg, #ededed 0deg)`;
        loaderProgress = (loaderProgress + 1) % 100;
    }
}
